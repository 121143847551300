<template>
  <v-row class="match-height">
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-col cols="12">
      <Breadcrumbs :items="items" />
    </v-col>
    <v-col cols="12">
      <v-card>
        <v-card-title>{{ formTitulo }}</v-card-title>
        <v-card-text>
          <v-form
            id="form"
            ref="form"
            v-model="isValid"
            class="multi-col-validation"
            :loading="loadingSaveForm"
            lazy-validation
          >
            <v-row>
              <v-col
                cols="12"
                md="6"
              >
                <v-text-field
                  v-model="name"
                  label="Nombre"
                  outlined
                  dense
                  placeholder="Nombre"
                  :rules="[
                    (v) => !!v || 'El campo nombre es obligatorio',
                    (v) => (!!v && v.length <= 50) || 'El campo nombre admite hasta 50 caracteres',
                  ]"
                  counter="50"
                  maxlength="50"
                  required
                  error-count="2"
                  :error="campos.name.error"
                  :error-messages="errorMsgName"
                >
                  <template #label>
                    <app-label
                      field="Nombre"
                      required="true"
                    />
                  </template>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="6"
              >
                <v-select
                  v-model="programCategoryId"
                  :items="listaCategorias"
                  item-value="id"
                  item-text="name"
                  label="Categoría"
                  placeholder="Categoría"
                  outlined
                  dense
                  :rules="[
                    (v) => !!v || 'El campo categoría es obligatorio',
                  ]"
                  required
                  error-count="2"
                  :error="campos.category.error"
                  :error-messages="errorMsgCategory"
                >
                  <template #label>
                    <app-label
                      field="Categoría"
                      required="true"
                    />
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  :disabled="!isValid"
                  :loading="loadingSaveForm"
                  @click="saveForm"
                >
                  Enviar
                </v-btn>
                <v-btn
                  type="reset"
                  outlined
                  class="mx-2"
                  @click="cancelarForm"
                >
                  Cancelar
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ref, reactive, computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import HTTP from '@/utils/axios/axios-config-base'
import AppLabel from '@/components/field/Label'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  components: { AppLabel, Breadcrumbs },
  created() {
    if (this.$route.params.obj != null) {
      this.obj.id = this.$route.params.obj.subCategoryId
      this.obj.name = this.$route.params.obj.subCategoryName
      this.obj.programCategoryId = this.$route.params.obj.categoryId
      this.name = this.$route.params.obj.subCategoryName
      this.programCategoryId = this.$route.params.obj.categoryId
      this.formTitulo = this.$route.params.formTitulo
      this.isValid = true
      this.items = this.$route.params.items
    }
  },
  setup() {
    const { router } = useRouter()
    const formTitulo = ref('Crear subcategoría')
    const loadingSaveForm = ref(false)
    const name = ref(null)
    const code = ref(null)
    const programCategoryId = ref(null)
    const obj = ref({
      id: null,
      name: null,
      programCategoryId: null,
    })
    const form = ref(null)
    const isValid = ref(true)
    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: 'Categorías',
        disabled: false,
        href: '/gestion-subcategorias-programas',
      },
      {
        text: `${formTitulo.value}`,
        disabled: true,
        href: '/gestion-subcategorias-programas/form',
      },
    ])
    const campos = reactive({
      name: {
        error: false,
        mensajesError: [],
      },
      category: {
        error: false,
        mensajesError: [],
      },
    })
    const errorMsgName = computed(() => (campos.name.error ? campos.name.mensajesError : []))
    const errorMsgCode = computed(() => (campos.code.error ? campos.code.mensajesError : []))
    const errorMsgCategory = computed(() => (campos.category.error ? campos.category.mensajesError : []))
    const listaCategorias = ref([])
    const listarCategorias = async () => {
      const URL = `${HTTP.defaults.baseURL}programs-api/program-categories?page=1&pageSize=1000000`
      const resp = await HTTP.get(URL, {})
      listaCategorias.value = []
      if (resp.data.value.entities.length > 0) {
        for (let index = 0; index < resp.data.value.entities.length; index += 1) {
          const obj = {
            id: resp.data.value.entities[index].id,
            name: resp.data.value.entities[index].name,
          }
          listaCategorias.value.push(obj)
        }
        // Ordenar alfabeticamente
        listaCategorias.value.sort((a, b) => {
          if (a.name.toLowerCase() === b.name.toLowerCase()) {
            return 0
          }
          if (a.name.toLowerCase() < b.name.toLowerCase()) {
            return -1
          }

          return 1
        })
      }
    }
    listarCategorias()
    function limpiarForm() {
      name.value = null
      code.value = null
      programCategoryId.value = null
      obj.value = {
        id: null,
        name: null,
        programCategoryId: null,
      }
    }
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })

    return {
      formTitulo,
      loadingSaveForm,
      name,
      programCategoryId,
      obj,
      listaCategorias,
      form,
      isValid,
      items,
      campos,
      errorMsgName,
      errorMsgCode,
      errorMsgCategory,
      notificaciones,
      saveForm: async () => {
        if (loadingSaveForm.value) return
        if (isValid && form.value.validate()) {
          loadingSaveForm.value = true
          campos.value = reactive({
            name: {
              error: false,
              mensajesError: [],
            },
            category: {
              error: false,
              mensajesError: [],
            },
          })
          const url = obj.value.id == null ? `${HTTP.defaults.baseURL}programs-api/programs-subcategories` : `${HTTP.defaults.baseURL}programs-api/programsubcategory/${obj.value.id}/programs-subcategories`
          const objeto = {}
          objeto.name = name.value
          objeto.programCategoryId = programCategoryId.value
          const resp = obj.value.id == null ? [HTTP.post(url, objeto)] : [HTTP.put(url, objeto)]
          await Promise.all(resp)
            .then(response => {
              notificaciones.snackbar = true
              notificaciones.color = 'success'
              notificaciones.text = obj.value.id == null ? 'El registro ha sido creado satisfactoriamente' : 'El registro ha sido actualizado satisfactoriamente'
              loadingSaveForm.value = false
              router.push({
                name: 'gestion_subcategorias_programas',
                params: {
                  notificaciones: {
                    snackbar: notificaciones.snackbar,
                    text: notificaciones.text,
                    color: notificaciones.color,
                  },
                },
              })
            })
            .catch(error => {
              const { errors, title } = error.response.data
              if (errors) {
                let errorDialog = true
                if (errors.Name) {
                  for (let index = 0; index < errors.Name.length; index += 1) {
                    campos.name.mensajesError.push(errors.Name[index])
                  }
                  errorDialog = false
                  campos.name.error = true
                }
                if (errors.programCategoryId) {
                  for (let index = 0; index < errors.programCategoryId.length; index += 1) {
                    campos.category.mensajesError.push(errors.programCategoryId[index])
                  }
                  errorDialog = false
                  campos.category.error = true
                }
                if (errorDialog) {
                  let error = ''
                  const keysError = Object.keys(errors)
                  for (let index = 0; index < keysError.length; index += 1) {
                    for (let index1 = 0; index1 < errors[keysError[index]].length; index1 += 1) {
                      error += errors[keysError[index]][index1]
                    }
                  }
                  notificaciones.snackbar = true
                  notificaciones.color = 'error'
                  notificaciones.text = error
                }
              }
              loadingSaveForm.value = false
            })
        }
      },
      cancelarForm: () => {
        limpiarForm()
        router.push({ name: 'gestion_subcategorias_programas' })
      },
    }
  },
}
</script>

<style>

</style>
